import React from 'react';
import './style.css';
import ReactPlayer from 'react-player'
import Button from '../../components/button/Button';

function News() {

  const cmcLink = () => {
    window.open('https://coinmarketcap.com/currencies/tron-guy-project/', '_blank'); 
  };


  return (
    <div id='news' className="news">
      <h2 data-aos="fade-up" data-aos-delay="0" >TronGuy Update</h2>
      <div className='news-row'>
        <div className='news-box' data-aos="fade-up" data-aos-delay="100">
          <h3>BTC Nashville</h3>
          <ReactPlayer
            width={'100%'}
            className='react-player'
            url='https://youtu.be/FdFRu0iWioo?feature=shared'
          />        
        </div>
        <div className='news-box' data-aos="fade-up" data-aos-delay="200">
          <h3>Token2049 Singapore</h3>
          <ReactPlayer
            width={'100%'}
            className='react-player'
            url='https://youtu.be/ghLl6fZKdPY'
          /> 
        </div>
        <div className='news-box' data-aos="fade-up" data-aos-delay="300">
          <h3>CMC listing</h3>
          <p>TRONGUY is the meme token dedicated to legendary internet celebrity Jay Maynard, better known as Tron Guy. As one of the internet’s first real-life memes, Tron Guy’s persona has been brought from Web 1.0 to Web 3.0, capturing the spirit of early internet culture and transforming it into a community-driven blockchain project. As the market cap grows, Tron Guy will be completing side quests personal to him, known as the bucket list initiative, the last of which will be taking a trip on a spaceship to the moon.</p>
          <br/>
          <Button onClick={cmcLink} text={'View'}/> 
        </div>
      </div>
    </div>
  );
}                         

export default News;
 