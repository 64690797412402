import React from 'react';
import './style.css';
import checked from './img/checked.png'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


function Roadmap() {
  const percentage = 99;
  const percentage2 = 31;
  const percentage3 = 0;
  const percentage4 = 0;


  return (
    <div className="roadmap">
      <h2 data-aos="fade-up" data-aos-delay="0" >Tron Guy Project Roadmap</h2>
      <div className="roadmap-grid">
        <div className='roadmap-item' data-aos="fade-up" data-aos-delay="100">
          <div className='title-row'>
            <h3>Phase 1: <span>Foundation</span></h3>
            <div className='meter'>
              <CircularProgressbar 
                value={percentage} 
                text={`${percentage}%`} 
                styles={buildStyles({
                  strokeLinecap: 'round',
                  textSize: '26px',
                  pathTransitionDuration: 0.5,
                  pathColor: `#60d5e7`,
                  textColor: '#60d5e7',
                  trailColor: '#3f535b',
                  backgroundColor: '#224751',
                })}
              />
            </div>            
          </div>
          <ul>
            <li className='checked'>
              Project Launch:
              <ul className='light-font'>
                <li>Official launch of Tron Guy coin. <img className='icon-checked' src={checked} alt=''/></li>
                <li>
                  Initial token distribution and listing on decentralized exchanges. <img className='icon-checked' src={checked} alt=''/>
                  <ul className='light-font'>
                    <li>JM/SS [Hyperlink] <img className='icon-checked' src={checked} alt=''/></li>
                  </ul>
                </li>
                <li>Develop and release the V1 official Tron Guy website.<img className='icon-checked' src={checked} alt=''/></li>
                <li>Publish official whitepaper<img className='icon-checked' src={checked} alt=''/></li>
              </ul>
            </li>
            <li className='checked'>
              Community Building:
              <ul className='light-font'>
                <li>Establish a social media presence (Twitter/X, Telegram, Discord).<img className='icon-checked' src={checked} alt=''/></li>
                <li>Engage with the community through AMAs, live sessions, and interactive posts.<img className='icon-checked' src={checked} alt=''/></li>
              </ul>
            </li>
            <li className='checked'>
              Token Utility:
              <ul className='light-font'>
                <li>Partnership with DeGenMerch Store<img className='icon-checked' src={checked} alt=''/></li>
              </ul>
            </li>
            <li>
              Initial Marketing:
              <ul className='light-font'>
                <li className='checked'>Launch the $TRONGUY airdrop campaign in collaboration with $CCC.<img className='icon-checked' src={checked} alt=''/></li>
                <li>Begin influencer outreach and partnerships.</li>
                <li className='checked'>Tron Ecosystem AMA’s<img className='icon-checked' src={checked} alt=''/></li>
                <li className='checked'>Establish Contact with Jay Maynard<img className='icon-checked' src={checked} alt=''/></li>
                <li className='checked'>First AMA ft. Jay Maynard<img className='icon-checked' src={checked} alt=''/></li>
              </ul>
            </li>
            <li className='checked'>
              Platform Development:
              <ul className='light-font'>
                <li className='checked'>Develop and release the V2 official Tron Guy website.<img className='icon-checked' src={checked} alt=''/></li>
                <li className='checked'>Tron Guy Partners with CCC and Unclaimed with character in-game.<img className='icon-checked' src={checked} alt=''/></li>
              </ul>
            </li>
          </ul>
        </div>
        <div className='roadmap-item' data-aos="fade-up" data-aos-delay="200">
          <div className='title-row'>
            <h3>Phase 2: <span>Development</span></h3>
            <div className='meter'>
              <CircularProgressbar 
                value={percentage2} 
                text={`${percentage2}%`} 
                styles={buildStyles({
                  strokeLinecap: 'round',
                  textSize: '26px',
                  pathTransitionDuration: 0.5,
                  pathColor: `#60d5e7`,
                  textColor: '#60d5e7',
                  trailColor: '#3f535b',
                  backgroundColor: '#224751',
                })}
              />
            </div>            
          </div>
          <ul>
            <li>
              Community Expansion:
              <ul className='light-font'>
                <li style={{paddingBottom:'3px'}}>List on Coingecko and Coinmarketcap</li>
                <li style={{paddingBottom:'3px'}}>Increase marketing efforts to attract users from other blockchain communities.</li>
                <li style={{paddingBottom:'3px'}}>Collaborations with KOL’s</li>
                <li style={{paddingBottom:'3px'}}>Attend other AMAs</li>
                <li style={{paddingBottom:'3px'}}>Meme Contests</li>
                <li style={{paddingBottom:'3px'}}>Community engagement rewards</li>
                <li style={{paddingBottom:'3px'}}>Continue Tron Guy AMA’s</li>
                <li style={{paddingBottom:'3px'}} className='checked'>Jay returns to the eye of the public (Bitcoin Nashville)<img className='icon-checked' src={checked} alt=''/></li>
                <li style={{paddingBottom:'3px'}}>Start an initiative to get the OG @TRONGUY Twitter handle back</li>
                <li style={{paddingBottom:'3px'}} className='checked'>Add Attend Token2049 Singapore<img className='icon-checked' src={checked} alt=''/></li>
                <li style={{paddingBottom:'3px'}}>Add List on CEX</li>
                <li style={{paddingBottom:'3px'}}>Add Attending “more 2025 Crypto events 2025”</li>
              </ul>
            </li>
            <li>
              Marketing:
              <ul className='light-font'>
                <li style={{paddingBottom:'3px'}}>Reveal Jay’s Bucket List initiative</li>
                <li style={{paddingBottom:'3px'}}>Activities and rewards for Jay at milestones to celebrate the Tron Guy</li>
                <li style={{paddingBottom:'3px'}} className='checked'>Continue AMA-s<img className='icon-checked' src={checked} alt=''/></li>
                <li style={{paddingBottom:'3px'}} className='checked'>Add Host Community Tron Megaspaces<img className='icon-checked' src={checked} alt=''/></li>
                <li style={{paddingBottom:'3px'}} className='checked'>Add recap video BTC Nashville<img className='icon-checked' src={checked} alt=''/></li>
                <li style={{paddingBottom:'3px'}} className='checked'>Add Recap video Token 2049 Singapore<img className='icon-checked' src={checked} alt=''/></li>
              </ul>
            </li>
            <li>
              Partnerships:
              <ul className='light-font'>
                <li style={{paddingBottom:'3px'}}>Secure strategic partnerships within the Tron ecosystem and beyond.</li>
                <li style={{paddingBottom:'3px'}}>Collaborate with notable projects for cross-promotions.</li>
              </ul>
            </li>
            <li>
              Product Development:
              <ul className='light-font'>
                <li>
                  Launch Tron Blockchain Educational Content Campaign.
                  <ul className='light-font'>
                    <li style={{paddingBottom:'3px'}}>(blockchain/crypto educational platform - fight for the user's social media campaign)</li>
                    <li style={{paddingBottom:'3px'}}>Release referral program for the educational program</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Community Rewards:
              <ul className='light-font'>
                <li style={{paddingBottom:'3px'}} className='checked'>Implement reward programs for active community members.<img className='icon-checked' src={checked} alt=''/></li>
                <li style={{paddingBottom:'3px'}} className='checked'>Regularly update the community with progress and achievements.<img className='icon-checked' src={checked} alt=''/></li>
              </ul>
            </li>
          </ul>
        </div>
        <div className='roadmap-item' data-aos="fade-up" data-aos-delay="300">
          <div className='title-row'>
            <h3>Phase 3: <span>Growth</span></h3>
            <div className='meter'>
              <CircularProgressbar 
                value={percentage3} 
                text={`${percentage3}%`} 
                styles={buildStyles({
                  strokeLinecap: 'round',
                  textSize: '26px',
                  pathTransitionDuration: 0.5,
                  pathColor: `#60d5e7`,
                  textColor: '#60d5e7',
                  trailColor: '#3f535b',
                  backgroundColor: '#224751',
                })}
              />
            </div>            
          </div>
          <ul>
            <li>
              Market Expansion:
              <ul className='light-font'>
                <li>List Tron Guy coin on centralized exchanges.</li>
                <li>
                  Continue aggressive marketing to increase token adoption.
                  <ul className='light-font'>
                    <li>Meme Creativity Contests</li>
                    <li>More In-Person Appearances of Tron Guy</li>
                    <li>Collaborations with large audience AMA producers</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Major Collaborations:
              <ul className='light-font'>
                <li>Partner with high-profile influencers and celebrities to promote Tron Guy.</li>
                <li>Tron Guy was brought back to TV</li> 
                <li>Gather user feedback </li>
              </ul>
            </li>
            <li>
              Social Media Paid Advertisement Campaigns Begin:
              <ul className='light-font'>
                <li>X, Instagram, Reddit</li>
                <li>
                  Tron Guy festival/Afterparty - always free for $TRONGUY holders
                  <ul className='light-font'>
                    <li>
                      Collaborate with TRONDAO
                      <ul className='light-font'>
                        <li>Prizes & Meet & Greet</li>
                      </ul>
                    </li>
                  </ul>
                </li> 
              </ul>
            </li>
          </ul>
        </div>
        <div className='roadmap-item' data-aos="fade-up" data-aos-delay="400">
          <div className='title-row'>
            <h3>Phase 4: <span>Maturity</span></h3>
            <div className='meter'>
              <CircularProgressbar 
                value={percentage4} 
                text={`${percentage4}%`} 
                styles={buildStyles({
                  strokeLinecap: 'round',
                  textSize: '26px',
                  pathTransitionDuration: 0.5,
                  pathColor: `#60d5e7`,
                  textColor: '#60d5e7',
                  trailColor: '#3f535b',
                  backgroundColor: '#224751',
                })}
              />
            </div>            
          </div>
          <ul>
            <li>
              Technological Advancements:
              <ul className='light-font'>
                <li>Introduce advanced features on the Tron Guy dApp (e.g., governance, advanced DeFi tools).</li>
                <li>Explore and integrate new technologies (e.g., AI, VR/AR) into the Tron Guy ecosystem.</li>
                <li>Launch innovative products and services to enhance user experience.</li>
              </ul>
            </li>
            <li>
              Global Outreach:
              <ul className='light-font'>
                <li>Expand marketing efforts globally to attract a wider audience.</li>
                <li>Participate in international blockchain conferences and events.</li>
                <li>More CEX listings</li>
                <li>International Crypto Conference appearance of Tron Guy</li>
              </ul>
            </li>
            <li>
              Sustainability:
              <ul className='light-font'>
                <li>Focus on long-term sustainability and ecosystem growth.</li>
                <li>Ensure continuous development and improvement of Tron Guy products.</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}                         

export default Roadmap;
 