import React, { useEffect, useState, useRef} from 'react';
import './style.css';
import { animated, useScroll, useTrail } from '@react-spring/web'
import tronguy from './img/tg.png'
import tron_logo from './img/tron-logo.png'
import pojazd from './img/pojazd.png'
import sunpump_logo_beta from './img/sunpump_logo_beta.svg'
import AOS from 'aos';


function Header({isWideScreen}) {
  const tronguyRef = useRef()
  const toGapRef = useRef()
  const [imgWidth, setImgWidth] = useState(427);
  const [heroGap, setHeroGap] = useState(0);
  const [toGap, setToGap] = useState(0);

  const updateWidth = () => {
    if (tronguyRef.current) {
      setImgWidth(tronguyRef.current.clientWidth);
      setHeroGap(tronguyRef.current.clientWidth / 2.9)
    }
    if (toGapRef.current) {
      setToGap(toGapRef.current.clientWidth - (imgWidth * .36))
    }
  };

  useEffect(() => {
    AOS.refresh();
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [tronguyRef?.current]);




  const { scrollYProgress } = useScroll()










  return (
    <div className="header">
      <div className='container'>
        <div>
          <h2 data-aos="fade-down" data-aos-delay="600">
            Now available on:
            <a 
              href='https://sunpump.meme/token/TEBD1GDSS6yWs4EFyoGxxP2LVAWW4v4ZMK' 
              target='_blank' 
              rel="noreferrer"
              style={{color: "#f8ba3a", textDecoration: 'none', paddingLeft: '13px'}}
            >
                <img src={sunpump_logo_beta} style={{width: '200px'}}/>
              </a>
          </h2>
          <h3 data-aos="fade-down" data-aos-delay="500">
            Tron Guy is a meme project {!isWideScreen && <br/>}for entertainment purposes only
          </h3>          
        </div>
        <h1>
          <b data-aos="fade-up" data-aos-delay="100">
            <div>
              TRON GUY 
            </div>
          </b>

          <div className='h1row' data-aos="fade-up" data-aos-delay="200">
            <div className='row_with_hero' style={{gap: heroGap - 15}}>
              <span ref={toGapRef} style={{textAlign: 'right'}}>the real</span>
              <img 
                ref={tronguyRef}
                data-aos="fade-up" 
                data-aos-delay="400"
                className='tronguy' 
                src={tronguy} 
                alt=''
                style={{left: `${toGap}px`, top: '-35px'}}
              /> 
              <span style={{textAlign: 'left'}}>life Meme!</span>               
            </div>

            
            <img 
              className='tron-logo' 
              src={tron_logo} 
              alt=''
            />  
          </div>  

        </h1>        
      
        <animated.img 
          className='pojazd'
          src={pojazd}
          alt=''
          style={{ opacity: scrollYProgress }}
        />

      </div>
    </div>
  );
}

export default Header;
