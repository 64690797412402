import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import Header from './sections/header/Header';
import AOS from 'aos';
import Navigation from './components/navigation/Navigation';
import News from './sections/news/News';
import Buy from './sections/buy/Buy';
import Whitepaper from './sections/whitepaper/Whitepaper';
import Stats from './sections/stats/Stats';
import Wallet from './sections/wallet/Wallet';
import Milestones from './sections/milestones/Milestones';
import Roadmap from './sections/roadmap/Roadmap';
import HowBuy from './sections/howbuy/HowBuy';
import Tokenomics from './sections/tokenomics/Tokenomics';
import Shop from './sections/shop/Shop';
import History from './sections/history/History';
import Team from './sections/team/Team';
import Contact from './sections/contact/Contact';
import Footer from './components/footer/Footer';
function App() {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 750);
  const [wideScreen, setWideScreen] = useState(window.innerWidth);

  const handleResize = () => {
    setIsWideScreen(window.innerWidth > 750);
    setWideScreen(window.innerWidth)
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    AOS.init({
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 500, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom',
    });
  }, []);

  return (
    <Router basename={'/'}>
      <div className="App">
        <Navigation/>
        <Header isWideScreen={isWideScreen}/>
        <News/>
        <Buy/>
        <Whitepaper/>
        <Stats/>
        <Wallet/>
        <Milestones wideScreen={wideScreen}/>
        <Roadmap/>
        <HowBuy wideScreen={wideScreen}/>
        <Tokenomics/>
        {/* <Shop wideScreen={wideScreen}/> */}
        <History/>
        <Team wideScreen={wideScreen}/>
        <Contact/>
        <Footer/>
      </div>      
    </Router>

  );
}

export default App;
