import React from 'react';
import './style.css';
import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'
import icon3 from './img/icon3.png'
import icon4 from './img/icon4.png'
import icon5 from './img/icon5.png'
import icon6 from './img/icon6.png'
import icon7 from './img/icon7.png'


function Stats() {
  return (
    <div className="stats">
      <div className='stats-row' style={{marginBottom: '40px'}}>
        <div className='stats-item' data-aos="fade-up" data-aos-delay="100" >
          <img src={icon1} alt=''/>
          <div className='stats-item-text'>
            <span>Max. supply:</span>
            <b>1 000 000 000</b>
          </div>
        </div>
        <div className='stats-item' data-aos="fade-up" data-aos-delay="200">
          <img src={icon2} alt=''/>
          <div className='stats-item-text'>
            <span>Token supply:</span>
            <b>1 000 000 000</b>
          </div>       
        </div>
        <div className='stats-item' data-aos="fade-up" data-aos-delay="300">
          <img src={icon3} alt=''/>
          <div className='stats-item-text'>
            <span>Contract Address:</span>
            <b style={{fontSize: '15px', paddingTop: '16px', fontWeight: 400}}>
             TEBD1GDSS6yWs4EFyoGxxP2LVAWW4v4ZMK
            </b>
          </div>        
        </div>
      </div>

      <hr/>
      
      <div className='stats-row' style={{gap: '100px'}}>
        <div className='stats-item' data-aos="fade-up" data-aos-delay="100"  style={{minWidth: '200px'}}>
          <img src={icon4} alt=''/>
          <span>LP BURNED</span>          
        </div>
        <div className='stats-item' data-aos="fade-up" data-aos-delay="200" style={{minWidth: '200px'}}>
          <img src={icon5} alt=''/>
          <span>TRON OG’s token</span>          
        </div>
        <div className='stats-item' data-aos="fade-up" data-aos-delay="300" style={{minWidth: '200px'}}>
          <img src={icon6} alt=''/>
          <span>Contract renounced</span>          
        </div>
        {/* <div className='stats-item-4' data-aos="fade-up" data-aos-delay="400">
          <img src={icon7} alt=''/>
          <span>23,4% tokens burned</span>          
        </div> */}
      </div>
    </div>
  );
}                         

export default Stats;
 