import React from 'react';
import './style.css';
import rex from './img/rex.png';
import mrs from './img/mrs.png';
import yanny from './img/yanny.png';
import barnacie from './img/barnacie.png';
import dleader from './img/dleader.png';
import mrvim from './img/mrvim.png';
import dendorian from './img/dendorian.png';
import poul from './img/poul.png';
import travis from './img/travis.png';
import kingatrocity from './img/kingatrocity.png';
import bryan from './img/bryan.png';
import jay from './img/jay.png';
import is12 from './img/is12.png';
import carapathian from './img/carapathian.png';
import satsover from './img/satsover.png';

import shopdege from './img/shopdege.png';
import ccc1 from './img/ccc1.png';
import ccc2 from './img/ccc2.png';
import babyturu from './img/babyturu.png';
import jm from './img/jm.png';
import uc from './img/uc.png';
import chickenwars from './img/chickenwars.png';

function Team({wideScreen}) {

  const Item = ({img, name, info, resize, tweeter}) => {

    const link = () => {
      window.open(tweeter, '_blank'); 
    };

    return (
      <div className='team-item'>
        <img 
          onClick={tweeter ? link : null} 
          src={img} 
          alt='' 
          width={resize && wideScreen < 750 && 79}
          style={tweeter ? {cursor: 'pointer'} : {}}
        />
        <div className='text'>
          <b className='name'>{name}</b>
          <span dangerouslySetInnerHTML={{__html: info}}></span>
        </div>
      </div>
    );
  }

  return (
    <div className="team">
      <div className='team-box'>
        <h2 data-aos="fade-up" data-aos-delay="0" style={{textAlign: 'center'}}>Tron Guy Team</h2>
        <div className='team-row' data-aos="fade-up" data-aos-delay="100">
          {/* <Item
            img={rex}
            name={'REX'}
            info={'<b>Netherlands, Age 40+,</b><br/> entrepreneur, have 3 companies, employ 60 people. Residency: Netherlands 5 years in Crypto'}
          /> */}
          {/* <Item
            img={carapathian}
            name={'Maurice'}
            info={'<b>Hungary, Age 51,</b><br/> 7 years in crypto, General Manager of Medical Business Unit for medium sized OEM.'}
          /> */}
          {/* <Item
            img={satsover}
            name={'Sats'}
            info={'<b>Boston, Age 29,</b><br/> Tradfi - Public Accounting'}
          /> */}
          <Item
            img={barnacie}
            name={'Barnacle'}
            info={'<b>Nashville, Age 44,</b><br/> 7 years in crypto, 14 years self-employed, 25 yrs business management ranging from retail to pharmaceutical. '}
            tweeter={'https://x.com/BarnacleTrx'}
          />
          <Item
            img={dleader}
            name={'D'}
            info={'<b>Boston, Age 32,</b><br/> 7 Years Crypto, Self employed 10 years, marketing solutions consultant'}
            tweeter={'https://x.com/dylN0chill'}
          />
          <Item
            img={jay}
            name={'Jay (Advisor)'}
            info={''}
            resize={true}
            tweeter={'https://x.com/JayMaynard'}
          />
          <Item
            img={mrvim}
            name={'Mr. Vim'}
            info={'<b>Virginia, USA, Age 59,</b><br/> 7 year in crypto, CIO/consultant/db programmer. Writer and creator of stories and lore for an NFT project for 3 years.'}
            tweeter={'https://x.com/Mr_VIMpossible'}
          />
          {/* <Item
            img={dendorian}
            name={'Dendo'}
            info={'<b>Netherlands, Age 35,</b><br/> 7 Years in crypto. Full time crypto'}
          /> */}
          <Item
            img={poul}
            name={'Paul'}
            info={'<b>Rhode Island, Age 32,</b><br/> 8 years construction/carpentry/cooling tower maintenance. 4 years in crypto'}
            tweeter={'https://x.com/silkyroads626'}
          />
        </div>
        <div className='team-row' data-aos="fade-up" data-aos-delay="200">
          <Item
            img={travis}
            name={'Travis'}
            info={'<b>Virginia , Age 41,</b><br/> 7 years crypto experience, self employed 18 years, construction industry '}
            tweeter={'https://x.com/trav_4211'}
          />
          <Item
            img={kingatrocity}
            name={'King'}
            info={'<b>Detroit, Age 30,</b><br/> 6 years crypto experience with degree in biochemistry. Leveraging exceptional pattern recognition skills for strategic partnerships and community growth'}
            tweeter={'https://x.com/KingAtrocity'}
          />
          <Item
            img={bryan}
            name={'bbryan927'}
            info={'<b>New York, Age 37,</b><br/> 7 years in crypto, 15 years as a technology teacher'}
            tweeter={'https://x.com/bbryan927'}
          />
          {/* <Item
            img={is12}
            name={'12'}
            info={'<b>California, Age 28,</b><br/> 7 years getting rugged in crypto.  Medical expert, underwater ceramic technician, CTO leader. '}
          /> */}
          {/* <Item
            img={mrs}
            name={'Mr. S'}
            info={'<b>Dubai, UAE, Age 32,</b><br/> 5 Years of Crypto/Web3 experience. Architect by profession, entrepreneur by passion.'}
          /> */}
          <Item
            img={yanny}
            name={'Yanny'}
            info={'<b>Warsaw, Poland, Age 38,</b><br/> 7 years in crypto - mostly on Tron, managing two companies (software/hardware producer)'}
          />
        </div>

        <h2 
          className={'sub-h2'} 
          data-aos="fade-up" 
          data-aos-delay="300" 
          style={{textAlign: 'center', fontSize: '35px'}}
        >
          Partners
        </h2>
        <div 
          className='team-row' 
          style={{marginBottom: '30px'}}
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <Item
            img={shopdege}
            name={''}
            info={''}
          />
          <Item
            img={ccc1}
            name={''}
            info={''}
          />
          <Item
            img={ccc2}
            name={''}
            info={''}
          />
          <Item
            img={babyturu}
            name={''}
            info={''}
          />
          <Item
            img={chickenwars}
            name={''}
            info={''}
          />
          <Item
            img={jm}
            name={''}
            info={''}
          />
          <Item
            img={uc}
            name={''}
            info={''}
          />
        </div>
      </div>
    </div>
  );
}

export default Team;